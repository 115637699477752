
import { ref, computed, defineComponent, reactive, toRefs, watch } from "vue";
import useTableHeight from "@/hooks/useTableHeight";
import useFetchCommon from "@/hooks/useFetchCommon";
import { getDealerList, exportDealerInfo } from "@/API/dealerMaster";
import Pagination from "@/components/Pagination.vue";
import { DealerListProps, DealerListQueryParams } from "./types";
import { useI18n } from "vue-i18n";
import { findEntityByids } from "@/API/approve";
import { getAreasDate } from "@/API/checking/claimDelayRuleSetup";
import { downloadFromStream } from "@/utils/payment/downloadFile";

export default defineComponent({
  name: "Dealer",
  components: {
    Pagination,
  },
  setup() {
    const { t, locale } = useI18n();

    const isEn = computed(() => {
      return locale.value === "en";
    });

    const columns = [
      {
        title: t("dealerData.status"),
        dataIndex: "statusName",
        key: "statusName",
        width: 100,
      },
      {
        title: t("dealerData.gsCode"),
        dataIndex: "gsCode",
        key: "gsCode",
        width: 160,
      },
      {
        title: t("dealerData.dealerCode"),
        dataIndex: "dealerCode",
        key: "dealerCode",
        width: 160,
      },
      {
        title: t("dealerData.dealerNameCn"),
        dataIndex: "nameCn",
        key: "nameCn",
        width: 260,
      },
      {
        title: t("dealerData.dealerNameEn"),
        dataIndex: "nameEn",
        key: "nameEn",
        width: 260,
      },
      {
        title: t("dealerData.dealerGroup"),
        dataIndex: "dealerGroupNameCn",
        key: "dealerGroupNameCn",
        width: 150,
      },
      {
        title: t("dealerData.provinceEn"),
        dataIndex: "provinceEn",
        key: "provinceEn",
        width: 150,
      },
      {
        title: t("dealerData.cityEn"),
        dataIndex: "cityEn",
        key: "cityEn",
        width: 150,
      },
      {
        title: t("dealerData.operation"),
        dataIndex: "operation",
        key: "operation",
        slots: { customRender: "operation" },
        width: 150,
      },
    ];
    const { tableHeight } = useTableHeight();

    const dealerListQueryParams = reactive<DealerListQueryParams>({
      dealerCode: "",
      dealerNameEn: "",
      dealerNameCn: "",
      dealerGroupNameCnList: [],
      provinceEnList: [],
      cityEnList: [],
      status: undefined,
    });

    const dealerGroupList = ref<any>([]);
    findEntityByids(10008).then((res) => {
      dealerGroupList.value = res;
    });

    const provinceList = ref<any>([]);
    const cityList = ref<any>([]);

    // const disabledCity = computed(() => {
    //   if (dealerListQueryParams.provinceEn) return false;
    //   return true;
    // });
    // watch(disabledCity, (newVal) => {
    //   if (newVal) {
    //     dealerListQueryParams.cityEn = undefined;
    //   }
    // });
    const getProvinceList = () => {
      getAreasDate().then((res: any) => {
        const provinces = res;
        provinceList.value = provinces;
        cityList.value = res.reduce((cities: any, pro: any) => {
          return [...cities, ...pro.cityList];
        }, []);
      });
    };
    getProvinceList();
    // const changeProvince = () => {
    //   if (dealerListQueryParams.provinceEn) {
    //     cityList.value = provinceCityMap[dealerListQueryParams.provinceEn];
    //   } else {
    //     cityList.value = [];
    //   }
    // };

    const statusList = [
      { label: "New", value: 1 },
      { label: "Active", value: 2 },
      { label: "InActive", value: 3 },
    ];

    const { data, pagination, refreshFetch } = useFetchCommon<
      DealerListProps[]
    >({
      params: dealerListQueryParams,
      initData: [],
      fetchApi: getDealerList,
      isPagination: true,
    });

    const handlerReset = () => {
      Object.assign(dealerListQueryParams, {
        dealerCode: "",
        dealerNameEn: "",
        dealerNameCn: "",
        dealerGroupNameCnList: [],
        provinceEnList: [],
        cityEnList: [],
        status: undefined,
      });
      pagination.page = 1;
      refreshFetch();
    };

    const handlerSearch = () => {
      pagination.page = 1;
      refreshFetch();
    };

    //分页事件处理
    const handlerPaginationChange = (page: number): void => {
      pagination.page = page;
      refreshFetch(false);
    };

    const handlerShowSizeChange = (current: number, pageSize: number): void => {
      pagination.size = pageSize;
      Object.assign(pagination, { page: current, size: pageSize });
      refreshFetch(false);
    };

    const handleExport = async () => {
      // 导出
      // exportDealerInfo(dealerListQueryParams).then((res: any) => {
      //   console.log(res);
      // });
      const data = await exportDealerInfo(dealerListQueryParams);
      downloadFromStream(data);
    };

    return {
      tableHeight,
      columns,
      data,
      pagination,
      refreshFetch,
      ...toRefs(dealerListQueryParams),

      handlerReset,
      handlerSearch,
      handlerPaginationChange,
      handlerShowSizeChange,

      isEn,
      dealerGroupList,
      provinceList,
      cityList,
      statusList,
      handleExport,
    };
  },
});
